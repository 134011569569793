import { ThirdPartyResourceEntityModel } from '@common-src/entity-model/third-party-resource-entity';

import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

// const URL_PATH = `${MESSAGE_BASE_REQUEST_PATH.slice(0, -8)}/clientApp`;
const URL_PATH = `/infra-auth/api/third/app/permission`;

class ThirdPartResourceService implements ICRUDQ<ThirdPartyResourceEntityModel, any> {
    async create(model: ThirdPartyResourceEntityModel, id?: string): Promise<ThirdPartyResourceEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string): Promise<ThirdPartyResourceEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ThirdPartyResourceEntityModel().toModel(res);
    }

    async update(model: ThirdPartyResourceEntityModel, id?: string): Promise<ThirdPartyResourceEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThirdPartyResourceEntityModel): Promise<ThirdPartyResourceEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(id: string): Promise<any> {
        const url = `${URL_PATH}/tree/${id}`;
        const res = await get(url);
        return res;
    }
}

export default new ThirdPartResourceService();
