













































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { ViewModeType, PermissionMediaType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ThirdPartyResourceEntityModel } from '@common-src/entity-model/third-party-resource-entity';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import ThirdPartResourceService from '@common-src/service/thirdpart-resource';
import ThirdPartyResourceDialog from './third-party-resource-dialog.vue';

@Component({
    components: {
        'third-party-resource-dialog': ThirdPartyResourceDialog
    }
})
export default class ThirdPartyResourceDrawer extends Mixins(DrawerComponent, TableDialogFormComponent) {
    ThirdPartyResourceEntityModel = ThirdPartyResourceEntityModel;
    model:any = null;
    tabsOptions: any = [];
    type: string = null;
    permissionList: any = [];

    drawerShow(model: any) {
        this.drawerTitle = '资源配置';
        this.model = model;
        this.drawerOpen(model.id, model.name);
        this.initTable({
            tableColumns: ThirdPartyResourceEntityModel.getTableColumns()
        });
        this.init(model.id);
    }

    get FilterTableData() {
        return _.find(this.permissionList, (item:any) => this.type === item.mediaType)?.permissions || [];
    }

    init(modelId: string) {
        ThirdPartResourceService.query(modelId).then(res => {
            this.permissionList = res;
            this.tabsOptions = _.map(res, (item: any) => {
                return {
                    key: item.mediaType,
                    name: this.formatPermissionMediaType(item.mediaType)
                };
            });
            this.type = this.type ? this.type : _.isEmpty(this.tabsOptions) ? null : this.tabsOptions[0].key;
            this.listData = _.find(res, (item:any) => this.type === item.mediaType)?.permissions || [];
        });
    }

    tabChangeHandler(key: string) {
        this.type = key;
        this.listData = this.FilterTableData;
    }

    reload() {
        this.init(this.model.id);
    }
    editClick(record:any) {
        const model = new ThirdPartyResourceEntityModel().toModel(record);
        model.clientId = this.model.clientId;
        model.appId = this.model.id;
        (this.$refs.thirdPartyResourceDialog as ThirdPartyResourceDialog).dialogOpen(model, null, ViewModeType.UPDATE);
    }

    deleteClickHandler(record:any) {
        ThirdPartResourceService.delete(record).then(res => {
            this.$message.success('删除成功');
            this.reload();
        }).catch(err => {
            this.$message.error('删除失败');
        });
    }

    formatPermissionMediaType(val:string) {
        switch (val) {
            case PermissionMediaType.PC:
                return 'Web端';
            case PermissionMediaType.WX:
                return '移动端';
            case PermissionMediaType.UNITY:
                return '大屏';
        }
    }

    addClickHandler(record?: any) {
        const model = new ThirdPartyResourceEntityModel();
        model.parentId = record?.id || null;
        model.appId = this.model.id;
        model.mediaType = this.type;
        (this.$refs.thirdPartyResourceDialog as ThirdPartyResourceDialog).dialogOpen(model, null, ViewModeType.NEW);
    }
}
