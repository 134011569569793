
import { ThirdPartyAppEntityModel, ThirdPartyAppFilterModel } from '@common-src/entity-model/third-party-app-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

// const URL_PATH = `${MESSAGE_BASE_REQUEST_PATH.slice(0, -8)}/clientApp`;
const URL_PATH = `/infra-auth/api/third/app`;

class ThirdPartAppService implements ICRUDQ<ThirdPartyAppEntityModel, ThirdPartyAppFilterModel> {
    async create(model: ThirdPartyAppEntityModel):Promise<ThirdPartyAppEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThirdPartyAppEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ThirdPartyAppEntityModel().toModel(res);
    }

    async update(model: ThirdPartyAppEntityModel):Promise<ThirdPartyAppEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThirdPartyAppEntityModel):Promise<ThirdPartyAppEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ThirdPartyAppFilterModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, (item, index) => ({ index: index + 1, ...item }));
        res.items = _.map(res.items, item => item = new ThirdPartyAppEntityModel().toModel(item));
        return res;
    }
}

export default new ThirdPartAppService();
