var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "import-excel-dialog-component" },
        [
          _c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME }),
          _c(
            "div",
            { staticClass: "ant-row ant-form-item flex align-center" },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "ant-form-item-required",
                      attrs: { title: "应用地址" },
                    },
                    [_vm._v("应用地址")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                  staticStyle: { color: "#a49d9d" },
                },
                [_vm._v(" 至少支持一项 ")]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "ant-row ant-form-item flex align-center" },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [
                  _c(
                    "label",
                    {
                      staticStyle: { color: "#a49d9d" },
                      attrs: { title: "Web端" },
                    },
                    [_vm._v("Web端")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入Web端地址" },
                    model: {
                      value: _vm.model.webUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "webUrl", $$v)
                      },
                      expression: "model.webUrl",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "ant-row ant-form-item flex align-center" },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [
                  _c(
                    "label",
                    {
                      staticStyle: { color: "#a49d9d" },
                      attrs: { title: "小程序" },
                    },
                    [_vm._v("小程序")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入小程序端地址" },
                    model: {
                      value: _vm.model.mpUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "mpUrl", $$v)
                      },
                      expression: "model.mpUrl",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "ant-row ant-form-item flex align-center" },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [
                  _c("label", { attrs: { title: "应用地址" } }, [
                    _vm._v("是否启用"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                },
                [
                  _c(
                    "a-checkbox",
                    {
                      model: {
                        value: _vm.model.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "status", $$v)
                        },
                        expression: "model.status",
                      },
                    },
                    [_vm._v("启用")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }