
























































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThirdPartyAppEntityModel, ThirdPartyAppFilterModel } from '@common-src/entity-model/third-party-app-entity';
import ThirdPartAppService from '@common-src/service/thirdpart-app';
import ThirdPartyResourceDrawer from './third-party-resource-drawer.vue';
import ThirdPartyAppDialog from './third-party-app-dialog.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'third-party-resource-drawer': ThirdPartyResourceDrawer,
        'third-party-app-dialog': ThirdPartyAppDialog
    }
})
export default class ThirdPartyAppComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    ThirdPartyAppEntityModel = ThirdPartyAppEntityModel;

    created() {
        this.initTable({
            service: ThirdPartAppService,
            queryModel: new ThirdPartyAppFilterModel(),
            tableColumns: ThirdPartyAppEntityModel.getTableColumns()
        });
        this.getList();
    }

    addClickHandler() {
        const model = new ThirdPartyAppEntityModel();
        (this.$refs.thirdPartyAppDialog as ThirdPartyAppDialog).dialogOpen(model, null, ViewModeType.NEW);
    }

    editClickHandler(model:any) {
        (this.$refs.thirdPartyAppDialog as ThirdPartyAppDialog).dialogOpen(model, null, ViewModeType.UPDATE);
    }

    resourceClick(model: ThirdPartyAppEntityModel) {
        (this.$refs.thirdPartyResourceDrawer as ThirdPartyResourceDrawer).drawerShow(model);
    }
}

