

















































import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';
import { FormControlType } from '@common-src/model/form-control';
import ThirdPartAppService from '@common-src/service/thirdpart-app';
import { ThirdPartyAppEntityModel } from '@common-src/entity-model/third-party-app-entity';
import { message } from 'ant-design-vue';

@Component
export default class ThirdPartyAppDialog extends FormDialogComponent<any> {
    model: ThirdPartyAppEntityModel = new ThirdPartyAppEntityModel();

    dialogOpen(model: ThirdPartyAppEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.model = model;
        this.dialogTitle = this.viewMode === ViewModeType.NEW ? '新增' : '编辑';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(model, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        if (!(this.model.webUrl || this.model.mpUrl)) {
            message.warning('请填写Web端地址或小程序地址');
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            this.model.id = this.jtlForm.formModel.id;
            this.model.clientId = this.jtlForm.formModel.clientId;
            this.model.name = this.jtlForm.formModel.name;
            this.model.identifier = this.jtlForm.formModel.identifier;
            if (this.viewMode === ViewModeType.NEW) {
                ThirdPartAppService.create(this.model).then(res => {
                    this.$message.success('添加成功');
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }

            if (this.viewMode === ViewModeType.UPDATE) {
                ThirdPartAppService.update(this.model).then(res => {
                    this.$message.success('修改成功');
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }
        }).catch(err => {
            throw err;
        });
    }
}
