var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "table-component" },
        [
          _c(
            "a-tabs",
            {
              attrs: { "active-key": _vm.type },
              on: { change: _vm.tabChangeHandler },
            },
            _vm._l(_vm.tabsOptions, function (item) {
              return _c("a-tab-pane", {
                key: item.key,
                attrs: { tab: item.name },
              })
            }),
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: _vm.Pagination,
              scroll: _vm.tableScroll,
              "row-key": "id",
              size: _vm.TableSize,
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addClickHandler(record)
                          },
                        },
                      },
                      [_vm._v("新增资源")]
                    ),
                    record.categoryDesc !== "应用"
                      ? [
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              staticClass: "jtl-edit-link",
                              on: {
                                click: function ($event) {
                                  return _vm.editClick(record)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认删除?",
                                "ok-text": "确认",
                                "cancel-text": "取消",
                                placement: "left",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.deleteClickHandler(record)
                                },
                              },
                            },
                            [
                              _c("a", { staticClass: "jtl-del-link" }, [
                                _vm._v("删除"),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload },
      }),
      _c("third-party-resource-dialog", {
        ref: "thirdPartyResourceDialog",
        on: { dialogOK: _vm.reload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }