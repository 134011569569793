import { render, staticRenderFns } from "./third-party-resource-drawer.vue?vue&type=template&id=22f47b59"
import script from "./third-party-resource-drawer.vue?vue&type=script&lang=ts"
export * from "./third-party-resource-drawer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins_home/workspace/test-物联业务中台(test-nimbus_web_bi)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22f47b59')) {
      api.createRecord('22f47b59', component.options)
    } else {
      api.reload('22f47b59', component.options)
    }
    module.hot.accept("./third-party-resource-drawer.vue?vue&type=template&id=22f47b59", function () {
      api.rerender('22f47b59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "nimbus-web-common/src/pages/dashboard/third-party/third-party-resource-drawer.vue"
export default component.exports