
import { BaseEntityModel } from '@common-src/model/base-model';
import { ColumnProps } from 'ant-design-vue/lib/table';
import { FormControl, FormControlType } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { PermissionType, ResourceType } from '@common-src/model/enum';

export class ThirdPartyResourceEntityModel extends BaseEntityModel {
    @FormControl({
        label: '资源名称',
        type: FormControlType.TEXT,
        required: true
    })
    name: string = null;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true
    })
    identifier: string = null;

    @FormControl({
        label: '类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: [
            { name: '页面', value: ResourceType.PAGE },
            { name: '功能', value: ResourceType.FUNCTION }
        ]
    })
    category: string = null;

    @FormControl({
        label: '路由',
        type: FormControlType.TEXT,
        placeholder: '请输入动态路由地址'
    })
    route: string = undefined;

    @FormControl({
        label: '序号',
        type: FormControlType.NUMBER
    })
    sortOrder: number = null;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    })
    remark: number = null;

    parentId: string = null;
    appId: string = null;
    categoryDesc: string = null;
    mediaType: string = null;

    static getTableColumns(): ColumnProps[] {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '序号',
                dataIndex: 'sortOrder'
            },
            {
                title: '类型',
                dataIndex: 'categoryDesc'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 180,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }
}
