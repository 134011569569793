import { BaseEntityModel } from '@common-src/model/base-model';
import { ColumnProps } from 'ant-design-vue/lib/table';
import { FormControl, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import ThirdPartlistService from '@common-src/service/thirdpart-list';

export class ThirdPartyAppEntityModel extends BaseEntityModel {
    @FormControl({
        label: '所属服务商',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: ThirdPartlistService.getOptions
    })
    clientId: string = undefined;

    @FormControl({
        label: '应用名称',
        type: FormControlType.TEXT,
        required: true
    })
    name: string = null;

    @FormControl({
        label: '应用标识符',
        type: FormControlType.TEXT,
        required: true
    })
    identifier: string = null;

    webUrl: string = null;
    mpUrl: string = null;
    groupName: string = null;

    status: boolean = false;
    clientName: string = undefined;
    index: number = undefined;

    createdTime: any = undefined;

    static getTableColumns(): ColumnProps[] {
        return [
            {
                title: '序号',
                dataIndex: 'index'
            },
            {
                title: '服务商名称',
                dataIndex: 'clientName',
                width: 120
            },
            {
                title: '应用名称',
                dataIndex: 'name',
                width: 150
            },
            {
                title: '应用标识符',
                dataIndex: 'identifier'
            },
            {
                title: '应用地址',
                dataIndex: 'webUrl'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                width: 200
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 250,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.status = _.get(json, 'status') === 'ENABLE';
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.status = data.status ? 'ENABLE' : 'DISABLE';
        return data;
    }
}

export class ThirdPartyAppFilterModel extends QueryPageModel {
    @QueryControl({
        label: '服务商名称',
        type: QueryControlType.TEXT
    })
    clientName: string = null;

    @QueryControl({
        label: '应用名称',
        type: QueryControlType.TEXT
    })
    name: string = null;

    toService() {
        const data: any = {};
        data.params = {
            clientName: this.clientName || null,
            name: this.name || null
        };
        return data;
    }
}
