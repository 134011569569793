

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';
import { FormControlType } from '@common-src/model/form-control';
import ThirdPartResourceService from '@common-src/service/thirdpart-resource';

@Component
export default class ThirdPartyResourceDialog extends FormDialogComponent<any> {
    model:any = null;
    dialogOpen(model: any, service: ICRUDQ<any, any>, viewMode: ViewModeType): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.model = model;
        this.dialogTitle = this.viewMode === ViewModeType.NEW ? '新增' : '编辑';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(model, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            if (this.viewMode === ViewModeType.NEW) {
                ThirdPartResourceService.create(this.jtlForm.formModel, this.model.appId).then(res => {
                    this.$message.success('添加成功');
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }

            if (this.viewMode === ViewModeType.UPDATE) {
                ThirdPartResourceService.update(this.jtlForm.formModel, this.model.appId).then(res => {
                    this.$message.success('修改成功');
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }
        }).catch(err => {
            throw err;
        });
    }
}
